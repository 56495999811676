import { Money } from "../../../services/models/cart.models";
import * as z from "zod";
import {
    FEEDBACK_TEXT,
    MAX_FULLNAME_LENGTH,
    validationHelper,
} from "../../validationHelpers";
import { GoogleAnalytics4EventModelSchema } from "../GoogleAnalytics/GoogleAnalyticsSchema";

export const MoneySchema = z.object({
    amount: z.number(),
    currency: z.string(),
});

export const emailSchema = z
    .string()
    .trim()
    .toLowerCase()
    .min(1, { message: FEEDBACK_TEXT().REQUIRED })
    .email({ message: FEEDBACK_TEXT().INVALID });

export const firstNameSchema = z
    .string()
    .trim()
    .min(1, { message: FEEDBACK_TEXT().REQUIRED })
    .superRefine((value, context) => {
        if (validationHelper.containsNumbers(value)) {
            context.addIssue({
                code: z.ZodIssueCode.custom,
                message: FEEDBACK_TEXT().CANNOT_CONTAIN_NUMBERS,
            });
        }
        if (
            validationHelper.containsDisallowedSpecialCharacters(
                "firstName",
                value,
            )
        ) {
            context.addIssue({
                code: z.ZodIssueCode.custom,
                message: FEEDBACK_TEXT().CANNOT_CONTAIN_SPECIAL_CHARACTERS,
            });
        }
    });

export const lastNameSchema = z
    .string()
    .trim()
    .min(1, { message: FEEDBACK_TEXT().REQUIRED })
    .superRefine((value, context) => {
        if (validationHelper.containsNumbers(value)) {
            context.addIssue({
                code: z.ZodIssueCode.custom,
                message: FEEDBACK_TEXT().CANNOT_CONTAIN_NUMBERS,
            });
        }
        if (
            validationHelper.containsDisallowedSpecialCharacters(
                "lastName",
                value,
            )
        ) {
            context.addIssue({
                code: z.ZodIssueCode.custom,
                message: FEEDBACK_TEXT().CANNOT_CONTAIN_SPECIAL_CHARACTERS,
            });
        }
    });

export const phoneNumberSchema = z
    .string()
    .trim()
    .superRefine((value, context) => {
        if (value.length < 1) {
            context.addIssue({
                code: z.ZodIssueCode.custom,
                message: FEEDBACK_TEXT().REQUIRED,
            });
        }
        if (value.length != 8) {
            context.addIssue({
                code: z.ZodIssueCode.custom,
                message: FEEDBACK_TEXT().PHONE_NUMBER_REQUIRED_LENGTH,
            });
        }
        if (validationHelper.containsSpecialCharacters(value)) {
            context.addIssue({
                code: z.ZodIssueCode.custom,
                message: FEEDBACK_TEXT().CANNOT_CONTAIN_SPECIAL_CHARACTERS,
            });
        }
        if (!validationHelper.isPhoneNumber(value)) {
            context.addIssue({
                code: z.ZodIssueCode.custom,
                message: FEEDBACK_TEXT().INVALID,
            });
        }
    });

export const addressSchema = z
    .string()
    .trim()
    .min(1, { message: FEEDBACK_TEXT().REQUIRED })
    .superRefine((value, context) => {
        if (
            validationHelper.containsDisallowedSpecialCharacters(
                "address",
                value,
            )
        ) {
            context.addIssue({
                code: z.ZodIssueCode.custom,
                message: FEEDBACK_TEXT().CANNOT_CONTAIN_SPECIAL_CHARACTERS,
            });
        }
    });

export const postalCodeSchema = z
    .string()
    .trim()
    .min(1, { message: FEEDBACK_TEXT().REQUIRED })
    .superRefine((value, context) => {
        if (value.length !== 4) {
            context.addIssue({
                code: z.ZodIssueCode.custom,
                message: FEEDBACK_TEXT().POSTAL_CODE_REQUIRED_LENGTH,
            });
        }
        if (!validationHelper.isPostalCode(value)) {
            context.addIssue({
                code: z.ZodIssueCode.custom,
                message: FEEDBACK_TEXT().POSTAL_CODE_REQUIRED_LENGTH,
            });
        }
    });

export const citySchema = z
    .string()
    .trim()
    .min(1, { message: FEEDBACK_TEXT().REQUIRED })
    .superRefine((value, context) => {
        if (validationHelper.containsNumbers(value)) {
            context.addIssue({
                code: z.ZodIssueCode.custom,
                message: FEEDBACK_TEXT().CANNOT_CONTAIN_NUMBERS,
            });
        }
        if (
            validationHelper.containsDisallowedSpecialCharacters("city", value)
        ) {
            context.addIssue({
                code: z.ZodIssueCode.custom,
                message: FEEDBACK_TEXT().CANNOT_CONTAIN_SPECIAL_CHARACTERS,
            });
        }
    });

export type KeySellingPoint = z.infer<typeof KeySellingPointSchema>;
export const KeySellingPointSchema = z.object({
    img: z.string(),
    heading: z.string(),
    description: z.string(),
});

export type PaymentMethodPreview = z.infer<typeof PaymentMethodPreviewSchema>;
export const PaymentMethodPreviewSchema = z.object({
    paymentMethodId: z.string(),
    systemKeyword: z.string(),
    imageId: z.string(),
});

export type LineItemModel = z.infer<typeof LineItemSchema>;
export const LineItemSchema = z.object({
    name: z.string().toLowerCase(),
    brand: z.string(),
    quantity: z.number(),
    code: z.string(),
    color: z.string(),
    size: z.string(),
    listPrice: MoneySchema,
    discountedPrice: MoneySchema,
    imageUrl: z.string().optional(),
    altText: z.string().optional(),
    productUrl: z.string().optional(),
    ean: z.string(),
    currentInventory: z.number(),
    memberPromotionsText: z.string().optional().nullable(),    
    memberPromotionsLink: z.string().optional().nullable(),
    esalesKey: z.string(),
});

export type Product = z.infer<typeof ProductSchema>;
export const ProductSchema = z.object({
    header: z.string(),
    name: z.string(),
    count: z.number(),
    size: z.string(),
    color: z.string(),
    shippingText: z.string(),
});

export type Cart = z.infer<typeof CartSchema>;
export const CartSchema = z.object({
    itemCount: z.number(),
    total: MoneySchema,
    subTotal: MoneySchema,
    subTotalWithoutDiscounts: MoneySchema,
    discountTotal: MoneySchema,
    selectedShippingMethodPrice: MoneySchema,
    lineItems: z.array(LineItemSchema),
    appliedCoupons: z.record(z.string()).optional(),
    paymentMethods: z.array(PaymentMethodPreviewSchema),
    freeShippingActive: z.boolean(),
    freeShippingLimit: MoneySchema,
    isAuthenticated: z.boolean(),
});

export type BaseAddressModel = z.infer<typeof BaseAddressModelSchema>;
const BaseAddressModelSchema = z.object({
    email: emailSchema,
    firstName: firstNameSchema,
    lastName: lastNameSchema,
    phoneNumber: phoneNumberSchema,
    address: addressSchema,
    postalCode: postalCodeSchema,
    city: citySchema,
});

export type AddressModel = z.infer<typeof AddressModelSchema>;
export const AddressModelSchema = BaseAddressModelSchema.superRefine(
    (data, ctx) => {
        if (
            (data.firstName?.length || 0) + (data.lastName?.length || 0) >=
            MAX_FULLNAME_LENGTH
        ) {
            ctx.addIssue({
                path: ["firstName"],
                code: z.ZodIssueCode.custom,
                message: FEEDBACK_TEXT().NAME_MAXLENGTH_EXCEEDED,
            });
            ctx.addIssue({
                path: ["lastName"],
                code: z.ZodIssueCode.custom,
                message: FEEDBACK_TEXT().NAME_MAXLENGTH_EXCEEDED,
            });
        }
    },
);

export type CheckoutInputModel = z.infer<typeof CheckoutInputModelSchema>;
export const CheckoutInputModelSchema = z.object({
    billingAddress: BaseAddressModelSchema.partial(),
    shippingAddress: BaseAddressModelSchema.partial(),
});

export type AddressModelWithoutAnalytics = z.
infer<typeof AddressModelWithoutAnalyticsSchema>;
export const AddressModelWithoutAnalyticsSchema = BaseAddressModelSchema.superRefine((data, ctx) => {
    if (
        (data.firstName?.length || 0) + (data.lastName?.length || 0) >=
        MAX_FULLNAME_LENGTH
    ) {
        ctx.addIssue({
            path: ["firstName"],
            code: z.ZodIssueCode.custom,
            message: FEEDBACK_TEXT().NAME_MAXLENGTH_EXCEEDED,
        });
        ctx.addIssue({
            path: ["lastName"],
            code: z.ZodIssueCode.custom,
            message: FEEDBACK_TEXT().NAME_MAXLENGTH_EXCEEDED,
        });
    }
});

export type PlaceholderAddressModel = z.infer<
    typeof PlaceholderAddressModelSchema
>;
export const PlaceholderAddressModelSchema = z.object({
    ...BaseAddressModelSchema.shape,
    email: z.string().min(0).optional(),
    firstName: z.string().min(0).optional(),
    lastName: z.string().min(0).optional(),
    phoneNumber: z.string().min(0).optional(),
    address: z.string().min(0).optional(),
    postalCode: z.string().min(0).optional(),
    city: z.string().min(0).optional(),
});

export type LoginModel = z.infer<typeof LoginModelSchema>;
export const LoginModelSchema = z.object({
    email: emailSchema,
    password: z.string().min(1, { message: FEEDBACK_TEXT().REQUIRED }),
});

export type OrderConfirmation = z.infer<typeof OrderConfirmationSchema>;
export const OrderConfirmationSchema = z.object({
    addressModel: AddressModelSchema,
    estimatedDeliveryTime: z.string(),
    paymentOption: z.string(),
    orderConfirmationText: z.string(),
    customerServiceText: z.string(),
    errorState: z.string().optional(),
    itemCount: z.string(),
    total: z.custom<Money>(),
    shippingCost: z.custom<Money>(),
    cartItems: z.array(LineItemSchema),
});

export type GetCartResponseModel = z.infer<typeof GetCartResponseModelSchema>;
export const GetCartResponseModelSchema = z.object({
    cartDto: CartSchema,
});

export type UpdateProductQuantityResponseModel = z.infer<typeof UpdateProductQuantityResponseModelSchema>;
export const UpdateProductQuantityResponseModelSchema = z.object({
    cartDto: CartSchema,
});

export type RemoveProductFromCartResponseModel = z.infer<typeof UpdateProductQuantityResponseModelSchema>;
export const RemoveProductFromCartResponseModelSchema = z.object({
    cartDto: CartSchema,
});