import axios, { AxiosResponse, CancelTokenSource } from "axios";

import {
    AutocompleteResult,
    SearchResult,
    CategoryResult,
    ProductsResult,
    CartStatusResult,
    SearchResultRecommendList,
} from "./models/search.models";

export * from "./models/search.models";

const TOUCHPOINT_DESKTOP = "DESKTOP";
const TOUCHPOINT_MOBILE = "MOBILE";

export default {
    /**
     * Notify click
     *
     * If browser does not support beacon: do an axios request and return promise.
     * Otherwise: sendBeacon.
     */
    notifyClick(ticket: string): undefined | Promise<AxiosResponse<any>> {
        const url = `/publicapi/esales/notify/click?ticket=${ticket}`;

        if (hasBeacon()) {
            navigator.sendBeacon(url);
        } else {
            return axios.post(url);
        }
    },
    /**
     * Autocomplete
     */
    autocomplete(
        cancelTokenSource: CancelTokenSource,
        searchQuery: string,
    ): Promise<AxiosResponse<AutocompleteResult>> {
        return axios.get("/publicapi/esales/autocomplete", {
            cancelToken: cancelTokenSource.token,
            params: {
                q: searchQuery,
                touchpoint: getTouchpoint(),
            },
        });
    },
    /**
     * Search
     */
    search(
        cancelTokenSource: CancelTokenSource,
        searchQuery: string,
        searchFacets: Object,
        sort: string | null,
        pageSize: number,
        page = 0,
        preview: string | undefined,
    ): Promise<AxiosResponse<SearchResult>> {
        const [query, skip, limit] = getSearchQuery(
            searchFacets,
            page,
            pageSize,
        );

        return axios.get("/publicapi/esales/search", {
            cancelToken: cancelTokenSource.token,
            params: Object.assign({}, query, {
                q: searchQuery,
                touchpoint: getTouchpoint(),
                sort: sort || undefined,
                skip,
                limit,
                PREVIEW: preview,
            }),
        });
    },
    /**
     * Category
     */
    category(
        cancelTokenSource: CancelTokenSource,
        pageId: string,
        searchFacets: Object,
        sort: string | null,
        pageSize: number,
        page = 0,
        preview: string | undefined,
        contentLinkId: number | undefined,
    ): Promise<AxiosResponse<CategoryResult>> {
        const [query, skip, limit] = getSearchQuery(
            searchFacets,
            page,
            pageSize,
        );

        return axios.get("/publicapi/esales/landing-page", {
            cancelToken: cancelTokenSource.token,
            params: Object.assign({}, query, {
                pageId,
                touchpoint: getTouchpoint(),
                sort: sort || undefined,
                skip,
                limit,
                PREVIEW: preview,
                contentLinkId: contentLinkId,
            }),
        });
    },
    /**
     * Products
     */
    products(
        apiUrl: string,
        baseUrlPath: string,
        limit: string | number,
    ): Promise<AxiosResponse<SearchResultRecommendList>> {
        return axios.get(apiUrl, {
            params: {
                path: baseUrlPath,
                touchpoint: getTouchpoint(),
                limit,
            },
        });
    },
    /**
     * Cart status
     */
    cartStatus(
        productId: string, // TODO
    ): Promise<AxiosResponse<CartStatusResult>> {
        const q = "jakke"; // TODO: Remove
        const sort = undefined; // TODO: Remove
        const skip = 0; // TODO: Remove
        const limit = 4; // TODO: Remove

        return axios.get("/publicapi/esales/search", {
            // TODO: Change to cart status API url
            params: Object.assign(
                {},
                {
                    // TODO: Double check this body when proper URL is provided
                    q,
                    touchpoint: getTouchpoint(),
                    sort: sort || undefined,
                    skip,
                    limit,
                },
            ),
        });
    },
};

function getSearchQuery(searchFacets: Object, page: number, pageSize: number) {
    const query = {};

    Object.keys(searchFacets).forEach((facetId) => {
        const facetValues = searchFacets[facetId].join("|");

        if (facetValues) {
            query[`f.${facetId}`] = facetValues;
        }
    });

    const skip = 0;
    const limit = (page + 1) * pageSize;

    return [query, skip, limit];
}

function hasBeacon() {
    return !!navigator.sendBeacon;
}

function getTouchpoint() {
    if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
        return TOUCHPOINT_MOBILE;
    }

    return TOUCHPOINT_DESKTOP;
}
