<script setup lang="ts">
import { formatWithoutRounding } from "../../mixins/productPrice.mixin";
import { formatSize } from "../../mixins/productSize.mixin";
import Icon from "../../components/icon/icon.vue";
import { computed, onMounted, ref, watch } from "vue";
import autoAnimate from "@formkit/auto-animate";
import { LineItemModel } from "../../utils/API/Cart/cartAPISchema";
import ShoppingCartError from "./shoppingCartError.vue";
import { USER_FEEDBACK } from "../../utils/API/APIErrors";
import ShoppingCartService from "../../services/shoppingcart.service";
import { useShoppingCartStore } from "./stores/shoppingCartStore";
import { useProductPageStore } from "../../stores/productPageStore";

const props = defineProps<{
  lineItem: LineItemModel;
  error?: string;
  outOfStock?: boolean;
}>();

const shoppingCartStore = useShoppingCartStore();
const productPageStore = useProductPageStore();
let abortController = new AbortController();
const root = ref();
const localQuantity = ref(props.lineItem.quantity);
const error = computed(() => {
  return props.error;
});

watch(localQuantity, (newQuantity, oldQuantity) => {
  abortController.abort();
  abortController = new AbortController();

  const quantity = newQuantity - oldQuantity;
  const isAdditive = true;

  ShoppingCartService.apiUpdateProductQuantity(
    props.lineItem.code,
    props.lineItem.ean,
    quantity,
    isAdditive,
    abortController.signal,
  );
});

/**
 * Multiplies the list price of the line item by the quantity.
 *
 * @returns {number} The total list price.
 */
const listPriceTotal = computed(() => {
  return props.lineItem.listPrice.amount * localQuantity.value;
});

/**
 * Multiplies the discounted price of the line item by the quantity.
 *
 * @returns {number} The total discounted price.
 */
const discountedPriceTotal = computed(() => {
  return props.lineItem.discountedPrice.amount * localQuantity.value;
});

// If the discounted price is less than the list price, the product is on sale
const discountActive = computed(() => {
  return (
    props.lineItem.discountedPrice.amount < props.lineItem.listPrice.amount
  );
});

/**
 * Validates the quantity of a line item.
 * The quantity must be greater than 0 and less than or equal to the current inventory.
 *
 * @param {number} quantity - The quantity to be validated.
 * @returns {boolean} - True if the quantity is valid, false otherwise.
 */
const validQuantity = (quantity: number) => {
  return quantity <= props.lineItem.currentInventory && quantity > 0;
};

const increaseQuantity = () => {
  const newQuantity = localQuantity.value + 1;

  updateQuantityError(newQuantity);
  if (validQuantity(newQuantity)) {
    localQuantity.value = newQuantity;
    productPageStore.incrementCartItemCount();
  }
};

const decreaseQuantity = () => {
  const prevLineItemCount = localQuantity.value;
  const newQuantity = Math.max(1, localQuantity.value - 1);

  updateQuantityError(newQuantity);
  if (validQuantity(newQuantity)) {
    localQuantity.value = newQuantity;
    if (prevLineItemCount > 1) {
      productPageStore.decrementCartItemCount();
    }
  }
};

/**
 * Handles the quantity error for the line item.
 * Sets an appropriate error if value is higher than the current inventory.
 * Otherwise clear the error.
 *
 * @param {number} newQuantity - The new quantity value.
 */
const updateQuantityError = (newQuantity: number) => {
  if (newQuantity > props.lineItem.currentInventory) {
    shoppingCartStore.setLineItemError(
      props.lineItem.ean,
      USER_FEEDBACK.INSUFFICIENT_STOCK,
    );
    return;
  }

  shoppingCartStore.setLineItemError(props.lineItem.ean, "");
};
/**
 * Deletes the product from the cart.
 */
const removeProduct = async () => {
  props.outOfStock
    ? ShoppingCartService.removeFromProductsOutOfStock(
        props.lineItem.code,
        props.lineItem.ean,
      )
    : ShoppingCartService.apiRemoveProductFromCart(
        props.lineItem.code,
        props.lineItem.ean,
      );
};

onMounted(() => {
  autoAnimate(root.value);
});
</script>

<template>
  <li
    ref="root"
    class="checkout-line-item"
    :class="{ 'checkout-line-item--out-of-stock': outOfStock }"
  >
    <div class="checkout-line-item__container">
      <div class="checkout-line-item__photo">
        <a :href="props.lineItem.productUrl">
          <img :src="props.lineItem.imageUrl" :alt="props.lineItem.altText" />
        </a>
      </div>
      <div class="checkout-line-item__content">
        <h3>
          <a class="checkout-line-item__name" :href="props.lineItem.productUrl">
            {{ props.lineItem.name }}
          </a>
          <span class="checkout-line-item__brand">
            ({{ props.lineItem.brand }})
          </span>
        </h3>
        <div class="checkout-line-item__details">
          <div class="checkout-line-item__price">
            <span
              v-if="discountActive"
              class="checkout-line-item__price--discounted"
            >
              {{ formatWithoutRounding(discountedPriceTotal) }}</span
            >
            <span
              :class="discountActive && 'checkout-line-item__price--original'"
            >
              {{ formatWithoutRounding(listPriceTotal) }}</span
            >
          </div>
          <div class="checkout-line-item__size">
            <span class="checkout-line-item__size--label"
              >{{ $t("size") }}:
            </span>
            <span class="checkout-line-item__size--value">{{
              formatSize(lineItem.size)
            }}</span>
          </div>
          <div class="checkout-line-item__color">
            <span class="checkout-line-item__color--label"
              >{{ $t("color") }}:
            </span>
            <span class="checkout-line-item__color--value">{{
              props.lineItem.color
            }}</span>
          </div>
        </div>
        <div
          v-if="!outOfStock"
          class="cart-item__actions checkout-line-item__quantity"
        >
          <input type="hidden" name="c" value="1933833" />
          <div
            class="line-item-select-quantity line-item-select-quantity--dark line-item-select-quantity--small"
          >
            <button
              class="line-item-select-quantity__button"
              :aria-label="$t('decreaseQuantity').toString()"
              @click="decreaseQuantity()"
            >
              -
            </button>
            <input
              v-model="localQuantity"
              type="number"
              class="line-item-select-quantity__input checkout-line-item__quantity-input"
              min="1"
              aria-live="assertive"
              aria-label="Antall"
              :readonly="true"
            />
            <button
              class="line-item-select-quantity__button"
              :aria-label="$t('increaseQuantity').toString()"
              @click="increaseQuantity()"
            >
              +
            </button>
          </div>
        </div>
      </div>
      <div class="checkout-line-item__remove-item">
        <button
          class="btn--icon"
          aria-label="fjern produkt fra handlekurv"
          @click="removeProduct()"
        >
          <Icon type="bin" size="checkout" />
        </button>
      </div>
    </div>
    <div class="checkout-line-item__trailer">
      <div v-if="props.lineItem.memberPromotionsText" class="checkout-line-item__members-promotions-text">        
        <div v-if="props.lineItem.memberPromotionsLink">
          <a :href="props.lineItem.memberPromotionsLink">{{ props.lineItem.memberPromotionsText }}</a>
        </div>
        <div v-else>
          {{ props.lineItem.memberPromotionsText }}
        </div>        
      </div>
    </div>
    <ShoppingCartError :error="error" type="line-item" />
  </li>
</template>
